'use strict';

import http from '@/utils/http';

export default {
  pageUrl: '/form/agreement/page',
  // 协议编辑保存
  updateSave(code, data) {
    return http.post('/form//agreement/saveOrUpdateAgreement', data, { code });
  },
  // 协议新增保存
  addSave(code, data) {
    return http.post('/form//agreement/saveOrUpdateAgreement', data, { code });
  },

  // 协议删除接口
  delete(code, params) {
    return http.delete('/form/agreement', { params, code });
  },

  // 保存修改企业版权描述接口
  updateTenantByCode(code, params) {
    return http.get(`/issmart-tenant/tenant/updateTenantByCode`, {
      params,
      code,
    });
  },

  // 请求协议结果页的接口
  getAgreement(code) {
    return http.get(`/issmart-tenant/tenant/getByCode/${code}`);
  },

  getInfo(code, tenant) {
    return http.get(`/issmart-tenant/tenant/getByCode/${tenant}`, { code });
  },
  updateInfo(code, data) {
    return http.put('/issmart-tenant/tenant', data, { code });
  },
  getEmail(code) {
    return http.get(
      '/issmart-messages/messageEmailChannel/getMessageEmailChannel',
      { code }
    );
  },
  //保存服务渠道的邮件配置信息
  updateEmail(code, data) {
    return http.put('/issmart-messages/messageEmailChannel', data, { code });
  },
  //获取服务渠道的短信配置信息
  getSMS() {
    return http.get('/issmart-messages/messageSmsChannel/getMessageSMSChannel');
  },
  //保存服务渠道的短信配置信息
  updateSMS(code, data) {
    return http.put('/issmart-messages/messageSmsChannel', data, { code });
  },
  getSMSSignList() {
    return http.get('/issmart-messages/messageSign/findByAllSign');
  },
  addSMSSign(data) {
    return http.post('/issmart-messages/messageSign', data);
  },
  deleteSMSSign(params) {
    return http.delete('/issmart-messages/messageSign', { params });
  },
  //获取服务渠道的微信配置信息
  getWeChat() {
    return http.get(
      '/issmart-messages/messageWechatChannel/getMessageWechatChannel'
    );
  },
  //保存服务渠道的微信配置信息
  updateWeChat(code, data) {
    return http.put('/issmart-messages/messageWechatChannel', data, { code });
  },
  // 更新保存控制国际开关是否打开
  upDataStatus(code, data) {
    return http.put('/issmart-tenant/tenant/upDataStatus', data, { code });
  },
  // 查询国际信息状态
  getTenantContent() {
    return http.get('/issmart-tenant/tenant/getTenantContent');
  },
};

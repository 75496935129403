<template>
  <div class="iss-main main-extra">
    <a-tabs tab-position="left">
      <a-tab-pane key="one" tab="协议配置">
        <div class="iss-grid" v-if="!show">
          <grid
            ref="gridRef"
            :columns="columns"
            :code="$route.path"
            :url="url"
            :url-params="{
              tenant: $store.state.account.tenant,
            }"
            :btn-operation="btnOperation"
            :scroll="{ x: 800, y: gridHeight }"
          >
            <template #operation="{ record }">
              <operation :options="options" :record="record" />
            </template>
          </grid>
        </div>
        <!-- 新增模块 -->
        <div v-else>
          <!--          <iss-form-add-page-->
          <!--            v-model:visible="show"-->
          <!--            :activeItem="activeItem"-->
          <!--            @fnOk="handleAddPage"-->
          <!--          />-->
          <div class="main-extra">
            <a-form layout="vertical" class="iss-form">
              <a-card :title="form.id ? '编辑' : '新增'">
                <a-row :gutter="20">
                  <a-col :span="24">
                    <a-form-item
                      label="协议名称"
                      v-bind="validateInfos.agreementName"
                    >
                      <a-input
                        v-model:value="form.agreementName"
                        placeholder="请输入协议名称"
                      />
                    </a-form-item>
                    <a-form-item
                      label="提交说明"
                      v-bind="validateInfos.agreement"
                    >
                      <a-textarea
                        v-model:value="form.agreement"
                        showCount
                        :maxlength="500"
                        placeholder="我愿意接受易思智公司及其授权的合作伙伴为我发送其产品、解决方案或服务的相关信息。我知道随时可以取消订阅。"
                      />
                    </a-form-item>
                    <a-form-item
                      label="使用条款说明"
                      v-bind="validateInfos.clause"
                    >
                      <a-textarea
                        v-model:value="form.clause"
                        :rules="clauseRules"
                        showCount
                        :maxlength="500"
                        placeholder="点击“提交”，表明我理解并同意按照易思智隐私保护条款与用户协议和传递我的个人信息。"
                      />
                    </a-form-item>
                    <a-form-item
                      label="隐私协议名称"
                      v-bind="validateInfos.privacyAgreementName"
                    >
                      <a-input
                        v-model:value="form.privacyAgreementName"
                        placeholder="隐私保护条款"
                      />
                    </a-form-item>
                    <a-form-item
                      label="隐私协议链接"
                      v-bind="validateInfos.privacyAgreementUrl"
                    >
                      <a-input
                        v-model:value="form.privacyAgreementUrl"
                        placeholder="http://www.xxxxx"
                      />
                    </a-form-item>
                    <a-form-item
                      label="用户协议"
                      v-bind="validateInfos.clauseName"
                    >
                      <a-input
                        v-model:value="form.clauseName"
                        placeholder="用户协议"
                      />
                    </a-form-item>
                    <a-form-item
                      label="用户协议链接"
                      v-bind="validateInfos.clauseUrl"
                    >
                      <a-input
                        v-model:value="form.clauseUrl"
                        placeholder="http://www.xxxxx"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-card>
              <a-form-item>
                <div class="iss-button">
                  <a-space :size="12">
                    <a-button type="primary" @click="handleOk"> 保存 </a-button>
                    <a-button @click="handleClickByCancel">取消</a-button>
                  </a-space>
                </div>
              </a-form-item>
            </a-form>
          </div>
        </div>
        <!--         编辑模块 -->
      </a-tab-pane>
      <a-tab-pane key="two" tab="版权描述" forceRender>
        <a-form layout="vertical" class="iss-form">
          <a-card title="版权设置">
            <a-row :gutter="20">
              <a-col :span="24">
                <a-form-item
                  label="版权编辑"
                  v-bind="copyright.validateInfos.copyrightDescription"
                >
                  <a-textarea
                    showCount
                    :maxlength="100"
                    placeholder="Copyright 2024 深圳易思智科技有限公司 All Rights Reserved"
                    v-model:value="copyrightForm.copyrightDescription"
                  >
                    {{ copyrightForm.copyrightDescription }}
                  </a-textarea>
                </a-form-item>
              </a-col>
            </a-row>
          </a-card>
          <a-form-item>
            <div class="iss-button">
              <a-space :size="12">
                <a-button type="primary" @click="handleClickBySave">
                  保存
                </a-button>
                <!--                <a-button @click="handleClickByCancel">取消</a-button>-->
              </a-space>
            </div>
          </a-form-item>
        </a-form>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { reactive, ref, toRefs } from 'vue';
import { Tabs, Card, Form, Col, Row, message } from 'ant-design-vue';
import Grid from '@/components/grid';
import enterpriseApi from '@/api/enterprise';
import Operation from '@/components/operation';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
// import log from "../../vipcenter/integral/components/log";

export default {
  components: {
    Grid,
    ATabs: Tabs,
    ACard: Card,
    ATabPane: Tabs.TabPane,
    AFormItem: Form.Item,
    AForm: Form,
    Operation,
    ARow: Row,
    ACol: Col,
    // IssFormAddPage,
    // IssFormEditPage,
  },
  setup() {
    const store = useStore();
    const code = store.state.account.tenant;
    const router = useRouter();
    const gridRef = ref();
    const tenant = store.state.account.tenant;
    const state = reactive({
      listId: '',
      show: false,
      ids: '',
    });
    const form = reactive({
      agreementName: '',
      agreement: '',
      clause: '',
      privacyAgreementName: '',
      privacyAgreementUrl: '',
      clauseName: '',
      clauseUrl: '',
      id: '',
      // copyrightDescription: '',
    });
    const copyrightForm = reactive({
      copyrightDescription: '',
    });
    const clauseNameValidator = (rule, value) => {
      if (form.clause.indexOf(value) !== -1) {
        return Promise.resolve();
      } else {
        return Promise.reject(
          '请输入上面使用条款中需要配置用户协议超链接的文字'
        );
      }
    };
    const privacyAgreementNameValidator = (rule, value) => {
      if (form.clause.indexOf(value) !== -1) {
        return Promise.resolve();
      } else {
        return Promise.reject(
          '请输入上面使用条款中需要配置隐私协议超链接的文字'
        );
      }
    };
    const required = { required: true, message: '不能为空' };

    const { validateInfos, resetFields, validate } = Form.useForm(form, {
      agreementName: [required, { max: 30, message: '不可超过30个字符' }],
      agreement: [required],
      clause: [required],
      privacyAgreementName: [
        required,
        {
          validator: privacyAgreementNameValidator,
          trigger: 'change',
        },
      ],
      privacyAgreementUrl: [
        required,
        { type: 'url', message: '请输入正确的URL地址' },
      ],
      clauseName: [
        required,
        {
          validator: clauseNameValidator,
          trigger: 'change',
        },
      ],
      clauseUrl: [required, { type: 'url', message: '请输入正确的URL地址' }],
    });
    const copyright = Form.useForm(copyrightForm, {
      copyrightDescription: [required],
    });
    // 请求默认协议
    enterpriseApi.getAgreement(tenant).then(res => {
      Object.assign(copyrightForm, res);
    });
    return {
      router,
      gridRef,
      form,
      code,
      copyright,
      copyrightForm,
      validateInfos,
      ...toRefs(state),
      url: enterpriseApi.pageUrl,
      gridHeight: document.body.clientHeight - 316,
      columns: [
        {
          dataIndex: 'agreementName',
          title: '名称',
        },
        {
          dataIndex: 'updateTime',
          title: '更新时间',
        },
        { dataIndex: 'createTime', title: '创建时间' },
        {
          key: 'id',
          fixed: 'right',
          title: '操作',
          width: 110,
          slots: { customRender: 'operation' },
        },
      ],
      options: [
        {
          type: 'update',
          label: '编辑',
          permission: 'enterprise:update',
          fnClick: record => {
            state.ids = record.id;
            state.show = true;
            // state.activeItem = record;
            Object.assign(form, record);
          },
        },
        {
          type: 'delete',
          label: '删除',
          permission: 'enterprise:delete',
          show: () => {
            const getList = gridRef.value.getDataSource();
            if (getList.length === 1) return false;
          },
          fnClick: record => {
            enterpriseApi.delete('', { id: record.id }).then(() => {
              message.success('操作成功！');
              gridRef.value.refreshGrid();
            });
          },
        },
      ],
      btnOperation: [
        {
          type: 'add',
          btnType: 'primary',
          label: '新增',
          icon: 'PlusOutlined',
          permission: 'enterprise:add',
          fnClick: () => {
            resetFields(), (form.id = ''), (state.show = true);
          },
        },
      ],
      handleClickByCancel: () => {
        console.log('关闭');
        state.show = false;
      },
      handleOk: () => {
        validate()
          .then(() => {
            if (form.id) {
              enterpriseApi.updateSave('', form).then(() => {
                state.show = false;
                message.success('操作成功！');
              });
            } else {
              enterpriseApi.addSave('', form).then(() => {
                state.show = false;
                message.success('操作成功！');
              });
            }
          })
          .catch(err => {
            console.log('error', err);
          });
        console.log('gridRef', gridRef);
        gridRef.value.refreshGrid();
      },
      handleClickBySave: () => {
        copyright.validate().then(() => {
          enterpriseApi
            .updateTenantByCode('', {
              copyrightDescription: copyrightForm.copyrightDescription,
              code,
            })
            .then(() => {
              message.success('操作成功！');
            });
        });
      },
    };
  },
};
</script>

<style lang="less" scoped>
.iss-form {
  padding-left: 20px;
  margin-left: -20px;
  max-height: calc(100vh - 138px);
  overflow-y: auto;
}
.iss-grid {
  margin: 20px 15px 15px -5px;
}
.iss-button {
  margin-bottom: 30px;
}
.sign-group {
  padding: 0 12px 0 10px;
  display: flex;
  align-items: center;
  .group-input {
    flex: 1;
  }
}
</style>
